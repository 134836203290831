export const deckFormats = {
    article: {
        name: "Article",
        category: "article",
        readerType: "htmldeck",
        inPlayer: false,
        icons: ["bullhorn", "magazine", "newspaper", "typewriter"],
        reader: "HTMLReader",
        ribbon: null,
    },
    story: {
        name: "Story",
        category: "story",
        readerType: "qdf",
        inPlayer: true,
        icons: ["quill2", "theater", "ticket2", "weather-windy"],
        reader: "QdfReader",
    },
    assessment: {
        name: "Assessment",
        category: "assessment",
        readerType: "assessment",
        inPlayer: true,
        icons: ["brain", "question4", "reading", "stack-text"],
        reader: "AssessmentReader",
    },
    game: {
        name: "Game",
        category: "game",
        readerType: "game",
        inPlayer: true,
        icons: ["gamepad", "dice", "pacman", "puzzle"],
        reader: "GameReader",
    },
    challenge: {
        name: "Challenge",
        category: "challenge",
        readerType: "challengedeck",
        inPlayer: true,
        icons: ["sun3", "price-tags", "gift2", "percent"],
        reader: "ChallengeReader",
    },
    survey: {
        name: "Survey",
        category: "survey",
        readerType: "survey",
        inPlayer: true,
        icons: ["file-text", "clipboard5", "file-stats2", "layers"],
        reader: "SurveyReader",
    },
    document: {
        name: "Document",
        category: "document",
        readerType: "docdeck",
        inPlayer: true,
        icons: ["file-text2", "page-break", "share3", "magazine"],
        reader: "DocumentReader",
    },
    video: {
        name: "Video",
        category: "video",
        readerType: "videck",
        inPlayer: false,
        icons: ["movie", "clapboard-play", "video-camera", "play3"],
        reader: "VideoReader",
    },
    reel: {
        name: "Reel",
        category: "reel",
        readerType: "reeldeck",
        inPlayer: true,
        icons: ["camera3", "heart3", "cool", "video2"],
        reader: "ReelReader",
    },
    checklist: {
        name: "Checklist",
        category: "checklist",
        readerType: "checklistdeck",
        inPlayer: false,
        icons: ["clipboard5", "menu3", "list", "file-check"],
        reader: "CheckListReader",
    },
    badge: {
        name: "Badge",
        category: "badge",
        readerType: "badgedeck",
        inPlayer: true,
        icons: ["star", "medal2", "rocket3", "chess-king"],
        reader: "BadgeReader",
    },
    scorm: {
        name: "SCORM",
        category: "scorm",
        readerType: "scormdeck",
        inPlayer: true,
        icons: ["cash", "credit-card", "price-tags", "stack4"],
        reader: "SCORMReader",
    },
    scormexam: {
        name: "SCORM Exam",
        category: "scormexam",
        readerType: "scormexam",
        inPlayer: true,
        icons: ["cash", "credit-card", "price-tags", "stack4"],
        reader: "SCORMExamReader",
    },
    certificate: {
        name: "Certificate",
        category: "certificate",
        readerType: "certdeck",
        inPlayer: true,
        icons: ["certificate", "stars", "user-tie", "sun3"],
        reader: "CertificateReader",
    },
    infographic: {
        name: "Infographic",
        category: "infographic",
        readerType: "infodeck",
        inPlayer: false,
        icons: ["chart-bars", "align-bottom", "angle", "dots"],
        reader: "InfographicReader",
    },
    gallery: {
        name: "Gallery",
        category: "gallery",
        readerType: "gallerydeck",
        inPlayer: false,
        icons: ["images", "pinter4", "stackoverflow", "finder"],
        reader: "GalleryReader",
    },
    reward: {
        name: "Reward",
        category: "reward",
        readerType: "rewarddeck",
        inPlayer: true,
        icons: ["gift2", "percent", "bubble-heart", "trophy4"],
        reader: "RewardReader",
    },
    webinar: {
        name: "Webinar",
        category: "webinar",
        readerType: "webinardeck",
        inPlayer: true,
        icons: ["bubble-video", "book-play", "collaboration", "earth"],
        reader: "WebinarReader",
    },
    event: {
        name: "Event",
        category: "event",
        readerType: "eventdeck",
        inPlayer: true,
        icons: ["calender4", "bullhorn", "camera3", "mic5"],
        reader: "EventReader",
    },
    news: {
        name: "News",
        category: "news",
        readerType: "newsdeck",
        inPlayer: false,
        icons: ["newspaper", "antenna2", "archive", "display4"],
        reader: "NewsReader",
    },
    faq: {
        name: "FAQ",
        category: "faq",
        readerType: "faqdeck",
        inPlayer: false,
        icons: ["question3", "cancel-circle2", "menu8", "checkmark-circle"],
        reader: "FAQReader",
    },
    rating: {
        name: "Rating",
        category: "rating",
        readerType: "ratingdeck",
        inPlayer: false,
        icons: ["star-empty2", "stairs-up", "power2", "arrow-up16"],
        reader: "RatingReader",
    },
    productcatalog: {
        name: "Product Catalog",
        category: "productcatalog",
        readerType: "productcatalogdeck",
        inPlayer: false,
        icons: ["notebook", "database-menu", "cart5", "benu2"],
        reader: "ProductCatalog",
    },
    productcard: {
        name: "Product Card",
        category: "productcard",
        readerType: "productcarddeck",
        inPlayer: true,
        icons: ["android", "at-sign", "bag", "basket"],
        reader: "ProductCard",
    },
    productgrid: {
        name: "Product Grid",
        category: "productgrid",
        readerType: "productgriddeck",
        inPlayer: true,
        icons: ["grid5", "books", "cube3", "dropbox"],
        reader: "ProductGrid",
    },
    productfaq: {
        name: "Product FAQ",
        category: "productfaq",
        readerType: "productfaqdeck",
        inPlayer: true,
        icons: ["question", "cancel-circle2", "menu8", "checkmark-circle"],
        reader: "ProductFAQ",
    },
    calculator: {
        name: "Calculator",
        category: "calculator",
        readerType: "calcdeck",
        inPlayer: true,
        icons: ["calculator2", "calculator3", "rulers", "angle"],
        reader: "CalculatorReader",
    },
    offer: {
        name: "Offer",
        category: "offer",
        readerType: "offerdeck",
        inPlayer: true,
        icons: ["sun3", "price-tags", "gift2", "percent"],
        reader: "OfferReader",
    },
    eoss: {
        name: "Eoss",
        category: "eoss",
        readerType: "eossdeck",
        inPlayer: false,
        icons: ["sun3", "price-tags", "gift2", "percent"],
        reader: "EossReader",
        ribbon: null,
        contentSchema: [],
    },
    meme: {
        name: "Meme",
        category: "meme",
        readerType: "memedeck",
        inPlayer: false,
        icons: ["wink2", "archery", "grin2", "bubble-star"],
        reader: "MemeReader",
    },
    iframe: {
        name: "Webpage",
        category: "iframe",
        readerType: "iframedeck",
        inPlayer: true,
        icons: ["presentation", "contract", "exclude", "checkbox-partial2"],
        reader: "IframeReader",
    },
    leaderboard: {
        name: "Leaderboard",
        category: "leaderboard",
        readerType: "leaderboarddeck",
        inPlayer: true,
        icons: ["podium", "medal-star", "trophy", "archery"],
        reader: "LeaderboardReader",
    },
    guidelines: {
        name: "Guidelines",
        category: "guidelines",
        readerType: "guidelinesdeck",
        inPlayer: true,
        icons: ["clipboard2", "bubble-lines3", "checkmark", "bubble-lock"],
        reader: "GuidelinesReader",
    },
    flashcards: {
        name: "Flash cards",
        category: "flashcards",
        readerType: "flashcardsdeck",
        inPlayer: true,
        icons: [
            "stack-pictures",
            "stack-overflow",
            "attachment",
            "bubble-lines3",
        ],
        reader: "FlashCardReader",
    },

    // Not Done Yet
    //---------------------------------

    scratchcards: {
        name: "scratchcards",
        category: "scratchcards",
        readerType: "scratchcarddeck",
        inPlayer: true,
        icons: ["cash", "credit-card", "price-tags", "stack4"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    course: {
        name: "Course",
        category: "course",
        readerType: "coursedeck",
        inPlayer: false,
        icons: ["books", "graduation", "reading", "library2"],
        reader: "CourseArticleReader",
        ribbon: null,
    },
    poll: {
        name: "Poll",
        category: "poll",
        readerType: "polldeck",
        inPlayer: true,
        icons: ["equalizer", "stats-growth2", "rating3", "transmission2"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    podcast: {
        name: "podcast",
        category: "podcast",
        readerType: "podcastdeck",
        inPlayer: true,
        icons: ["podcast", "headset", "media", "mic"],
        reader: "PodcastReader",
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    playlist: {
        name: "playlist",
        category: "playlist",
        readerType: "playlistdeck",
        inPlayer: true,
        icons: ["playlist", "file-music", "speakers", "next"],
        reader: "PlaylistReader",
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },

    discussion: {
        name: "Discussion",
        category: "discussion",
        readerType: "discussiondeck",
        inPlayer: false,
        icons: ["bubbles2", "lan3", "users2", "thumbs-up3"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    photosubmission: {
        name: "Photo Submission",
        category: "photosubmission",
        readerType: "photosubmissiondeck",
        inPlayer: true,
        icons: ["enlarge5", "enter2", "file-picture2", "happy2"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    profiler: {
        name: "Profiler",
        category: "profiler",
        readerType: "profilerdeck",
        inPlayer: true,
        icons: ["profile", "user5", "bubble-user", "safe"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    recommender: {
        name: "recommender",
        category: "recommender",
        readerType: "recommenderdeck",
        inPlayer: true,
        icons: ["map", "mail4", "puzzle3", "reminder"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    responsivetable: {
        name: "Responsive Table",
        category: "responsivetable",
        readerType: "responsetabledeck",
        inPlayer: true,
        icons: ["chart", "pie-chart8", "table2", "equalizer3"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },

    videosubmission: {
        name: "Video Submission",
        category: "videosubmission",
        readerType: "visubmissiondeck",
        inPlayer: true,
        icons: ["file-video2", "stack-plus", "video-camera5", "upload5"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    documentsubmission: {
        name: "Document Submission",
        category: "documentsubmission",
        readerType: "docsubmisiondeck",
        inPlayer: true,
        icons: ["stack-up", "file-zip2", "file-upload2", "file-check"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    dialogue: {
        name: "Dialogue",
        category: "dialogue",
        readerType: "dialoguedeck",
        inPlayer: true,
        icons: ["bubble", "megaphone", "bubble-dots3", "finder"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    spotlight: {
        name: "Spotlight",
        category: "spotlight",
        readerType: "spotlightdeck",
        inPlayer: true,
        icons: ["spotlight2", "spotlight", "fire", "power2"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    interactivevideo: {
        name: "Interactive Video",
        category: "interactivevideo",
        readerType: "interactivevideck",
        inPlayer: true,
        icons: ["file-play2", "film4", "book-play", "pause"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    listicle: {
        name: "Listicle",
        category: "listicle",
        readerType: "listicledeck",
        inPlayer: false,
        icons: ["list", "menu2", "file-text", "lan2"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    interactivecharts: {
        name: "Interactive Charts",
        category: "interactivecharts",
        readerType: "interactivechartsdeck",
        inPlayer: true,
        icons: ["pie-chart", "factory", "equalizer3", "file-stats2"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    jobpost: {
        name: "Job Post",
        category: "jobpost",
        readerType: "jobpostdeck",
        inPlayer: false,
        icons: ["magazine", "user-tie", "reading", "vcard"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    downloadable: {
        name: "Downloadable",
        category: "downloadable",
        readerType: "downloadabledeck",
        inPlayer: true,
        icons: ["download", "stack-down", "copy3", "envelop5"],
        reader: null,
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
    default: {
        inPlayer: true,
        category: "default",
        icons: ["bubble-quote", "bubble5", "bubble-smiley", "bubble-lines4"],
        reader: "HTMLReader",
        ribbon: {
            home: {
                visible: true,
                sections: ["save", "file", "view", "settings", "audit"],
            },
        },
    },
};
